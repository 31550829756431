<script setup>
import Utility, { handleApiResponse } from '~/server/utils/utils';

const runtimeConfig = useRuntimeConfig();

// Initialize route and i18n
const route = useRoute();
const { t, locale } = useI18n();
let lang = locale.value.split('-')[0];

const rule = useRobotsRule();
if (rule) {
  rule.value = 'noindex, nofollow';
}

// Initialize head using useLocaleHead
let head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
});

// Compute the page title
const title = computed(() => route.meta.title ?? t('TourScanner - Find your ideal travel activity at the best price'));

// Default Open Graph Image
const defaultOgImage = 'https://tourscanner.com/blog/wp-content/uploads/og_image.png';

// Function to update href links
const updateHrefsLinks = async (currentPath) => {
  const urlScomposed = Utility.scomposeURL(currentPath);
  const isStrapiPage = route?.name?.includes('slug');
  const isMapPage = route?.name?.includes('map');

  // Check if any of the 4 parameters are present
  if (
    urlScomposed.result.s ||
    urlScomposed.result.c ||
    urlScomposed.result.i ||
    urlScomposed.result.t ||
    isMapPage ||
    isStrapiPage
  ) {
    head = useLocaleHead({
      addDirAttribute: true,
      identifierAttribute: 'id',
      addSeoAttributes: false,
    });

    try {
      // If the path contains "artcles_test" then take the language from the part after "blog"
      // DEPRECATED on 17/12/2024, we removed the blog part from the path
      // if (route.path.split('/').includes('blog')) {
      //   if (['de', 'fr', 'it', 'nl', 'pt', 'es'].includes(lang)) {
      //     lang = route.path.split('/')[2];
      //     locale.value = lang;
      //   }
      // }

      // Call the API to get href links
      const { data } = await handleApiResponse(`/api/getHrefsLinks`, {
        params: {
          path: currentPath,
          strapi: isStrapiPage,
          lang: lang,
        },
      });

      // Map the API response to match the head links structure
      const apiLinks =
        data.value?.map((link) => ({
          ...link,
          id: link.id, // Ensure the ID matches the one set by useLocaleHead
        })) || [];

      // Get the canonical path
      let canonicalPath;
      canonicalPath = `${runtimeConfig.public.siteURL}${route.path}`;
      // Update the canonical link
      apiLinks.push({
        rel: 'canonical',
        href: canonicalPath,
        id: 'canonical',
        hreflang: lang,
      });

      // Update the head links
      head.value.link = apiLinks;

      useHead({
        title: () => title.value,
        meta: [
          { name: 'robots', content: rule.value },
          { name: 'apple-itunes-app', content: 'app-id=1496550592, app-argument=https://tourscanner.com' },
          { name: 'mobile-web-app-capable', content: 'yes' },
          { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
          { name: 'apple-mobile-web-app-title', content: 'TourScanner' },
          { name: 'theme-color', content: '#33B1E3' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' },
          { name: 'og:image', content: route.meta.ogImage || defaultOgImage },
          ...head?.value?.meta,
        ],

        link: [
          ...apiLinks.map((link) => ({ rel: link.rel, href: link.href, id: link.id, hreflang: link.hreflang })),
          { rel: 'apple-touch-icon', href: '/website/public/apple-touch-icon.png' },
        ],
      });

      useSeoMeta({
        ogUrl: () => `${runtimeConfig.public.siteURL}${route.path}`,
      });
    } catch (error) {
      head.value.link = [];
    }
  }
};

// Watch for route changes and update href links accordingly
watch(
  () => route.path,
  (newPath) => {
    updateHrefsLinks(newPath);
  },
  { immediate: true },
);
</script>

<template>
  <slot />
</template>
